import React, { useEffect, useRef, useState } from 'react';
import '../../CSS/Perplexity.css';
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import emailjs from "emailjs-com";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import clock from "../../images/perplexity-page/clock-image.png";
import Insightdata from "../../Data/Resources-Data/Insightdata.js";
import linkedin from "../../images/perplexity-page/linkedin.svg";
import facebook from "../../images/perplexity-page/facebook.svg";
import twitter from "../../images/perplexity-page/twitter.svg";
import instagram from "../../images/perplexity-page/instagram.svg";
import { Helmet } from "react-helmet";

const schema = yup
    .object()
    .shape({
        fName: yup
            .string()
            .matches(/^[a-zA-Z ]*$/, "First Name is not valid")
            .required("First Name is required"),
        lName: yup
            .string()
            .matches(/^[a-zA-Z ]*$/, "Last Name is not valid")
            .required("Last Name is required"),
        email: yup
            .string()
            .email()
            .matches(
                /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
                "email is required"
            )
            .required("email is required"),
        // phoneNumber: yup.string().when("$exist", {
        //   is: (exist) => exist,
        //   then: yup
        //     .string()
        //     .matches(
        //       /^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}$/,
        //       "phonenumber is not valid"
        //     ),
        //   otherwise: yup.string(),
        // }),
        // acceptCheckbox: yup.bool().oneOf([true], "Checkbox selection is required"),
        cName: yup
            .string()
            .matches(/^[a-zA-Z ]*$/, "Company Name is not valid")
            .required("Company is required"),
    })
    .required();

const headings = [
    {
        "id": "heading1",
        title:"",
        "description": "We need AIOps (Artificial Intelligence for IT Operations) because modern IT environments have become increasingly complex and difficult to manage using traditional methods. AIOps leverages machine learning and artificial intelligence algorithms to automate and streamline IT operations, making it easier for IT teams to monitor, manage, and optimize their IT environments.",
        "heading1": "AIOps is particularly well-suited to modern IT environments for several reasons:",
        "orderedlist": [
          {
            "sidehead": "Scale",
            "content": "Modern IT environments are often distributed across multiple cloud providers and data centers, with thousands or even millions of devices and applications. AIOps can process and analyze large amounts of data in real-time, identifying patterns and anomalies that may be missed by humans."
          },
          {
            "sidehead": "Speed",
            "content": "Modern IT environments require rapid response times to meet customer expectations and prevent downtime. AIOps can help detect and respond to issues in real-time, often before they become visible."
          },
          {
            "sidehead": "Complexity",
            "content": "AIOps can help IT teams understand and manage complexity by identifying relationships and dependencies between components of the IT environment."
          },
          {
            "sidehead": "Costs",
            "content": "Traditional IT operations can be expensive, requiring large teams of highly skilled professionals. AIOps can help reduce costs by automating repetitive tasks, improving efficiency, and reducing the risk of downtime and other costly incidents."
          }
        ]
    },
    { 
        id:"heading2",
        "title": "AIOps' Key Capabilities",
        "description": "AIOps uses advanced analytics and machine learning algorithms to analyze vast amounts of data generated by IT systems in real-time. This includes data from logs, performance, metrics, events, and alerts. By analyzing this data, AIOps can identify patterns and anomalies that could indicate potential issues before they occur. This helps IT teams proactively address issues before they impact system availability or performance.",
        "description1": "One of the key benefits of AIOps is that it helps IT teams better manage the complexity of modern IT systems. With so many different systems, applications, and services running in today’s IT environments, it can be difficult to keep track of everything. AIOps provides a unified view of all the data generated by these systems, making it easier for IT teams to identify and resolve issues quickly.",
        "description2": "The strongest and most noticeable advantage of AI for IT operations is how it improves and strengthens the IT operations team. IT operations can find, address, and fix latencies with AIOps a lot more quickly than by manually sorting through alerts and data points.",
        "heading1": "This has numerous positive effects on an entire enterprise, including the following:",
        "orderedlist": [
          {
            "sidehead": "Faster Incident Response",
            "content": "Reduce the amount of time it takes to respond to an incident to achieve a faster Mean Time To Resolution (MTTR). AIOps locates anomalies in the data pool and makes suggestions for fixes more quickly than is humanly possible."
          },
          {
            "sidehead": "Continuous Learning",
            "content": "AIOps never stops learning; thus, it continually improves at spotting and notifying urgent situations, or even at solving them. Predictive notifications can be offered, enabling IT staff to address possible issues before they cause sluggishness or disruptions."
          },
          {
            "sidehead": "Strategic Focus",
            "content": "AIOps allows ITOps teams to focus on strategic areas while AIOps evolves to take on lesser, more tedious tasks."
          },
          {
            "sidehead": "Dynamic Thresholds",
            "content": "AIOps tools can set dynamic thresholds for monitoring metrics, ensuring that alerts are triggered only when there is a significant deviation from normal behavior."
          },
          {
            "sidehead": "Continuous Monitoring",
            "content": "AIOps tools can continuously monitor IT infrastructure and applications, providing real-time visibility into performance and availability."
          },
          {
            "sidehead": "Comprehensive IT View",
            "content": "AIOps technologies can combine data from several sources, including metrics, events, and logs, to create a comprehensive picture of the IT environment."
          }
        ]
      },
      
      {
        id:"heading3",
        "title": "The Top 5 Causes for the Growing Need for AIOps",
        "description": "Today’s IT environments are becoming increasingly complex, with a large number of devices, applications, and services distributed across on-premises and cloud environments. This complexity makes it difficult for IT teams to manually monitor and manage the environment, leading to the need for AI-based automation.",
        "description1": "The amount of data generated by IT systems is growing at an exponential rate. Traditional IT monitoring tools are not equipped to handle this volume of data, leading to the need for AI-based tools that can process and analyze large amounts of data quickly.",
        "description2": "Customers today expect 24*7 availability and rapid response times from the IT systems they use. AIOps helps IT teams proactively identify and address issues before they impact customers.",
        "description3": "IT organizations are under pressure to deliver more value with fewer resources. AIOps can reduce costs by automating repetitive tasks, improving efficiency, and reducing downtime.",
        "description4": "Cybersecurity threats are becoming more sophisticated and frequent, making it difficult for IT teams to keep up. AIOps can help detect and respond to security threats in real-time, reducing the risk of data breaches and other security incidents."
      }
      ,
      {
        id:"heading4",
        "title": "Conclusion",
        "description": "In conclusion, AIOps is an innovative technology that is transforming the retail industry by providing real-time visibility, data analysis, and automation capabilities. AIOps can help retailers streamline their operations and retail merchandising, improve the customer experience, and reduce costs. However, retailers need to ensure that they address the challenges they face and invest in robust data security measures to fully realize the benefits of AIOps technology.",
       "description5":"Leading retailers are experiencing the biggest challenge in choosing the right AI/ML advisor toward digital transformation.",
     description6:"We can help you overcome the problem by offering you a personalized and no-obligation consultation.",
     description7:"If this is your highest priority, please feel free to schedule a call with us!",
    }
      
      
      
      
];




const AIOPenvironment = () => {
    const [show, setShow] = useState(false);
    const handleShow = () => setShow(true);
    const handleClose = () => setShow(false);
    const history = useNavigate();
    const btnColor = {
        backgroundColor: "#ecac27",
        borderRadius: "50px",
        cursor: "pointer",
    };

    const navigateTowebinarspage = (id) => {
        history(`/blogs/${id}`);
    };

    const [selectedId, setId] = useState("");
    const hightlightText = (id) => {
        setId(id);
    };

    const UnhightlightText = () => {
        setId("");
    };

    const {
        register,
        handleSubmit,
        formState: { errors },
        reset,
    } = useForm({
        resolver: yupResolver(schema),
    });

    const submit = (data) => {
        return emailjs
            .sendForm(
                "service_e7ig9fn",
                "template_g62rj9d",
                formDetails.current,
                "yz7dQlM6o3Rz3cnB8"
            )
            .then(
                console.log("mail sent"),
                localStorage.setItem("cookie", 4),
                reset(),
                handleShow(),
                (error) => {
                    console.log(error.text);
                }
            );
    };

    const formDetails = useRef();
    const [activeHeading, setActiveHeading] = useState(null);
    const descriptionsRef = useRef([]);

    const scrollToSection = (id) => {
        const section = document.getElementById(id);
        if (section) {
            section.scrollIntoView({ behavior: 'smooth' });
        }
    };

    const handleScroll = () => {
        const sections = descriptionsRef.current;
        sections.forEach((section) => {
            const rect = section.getBoundingClientRect();
            if (rect.top >= 0 && rect.top < window.innerHeight) {
                setActiveHeading(section.id);
            }
        });
    };

    useEffect(() => {
        window.addEventListener('scroll', handleScroll);
        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);


    return (
        <>
        <Helmet>
        <meta charSet="utf-8" />
        <title>Learn How AIOps fits in the Modern IT Environment | Amnet Digital</title>
        <meta name="description" content="Discover how AIOps, the cutting-edge technology that combines artificial intelligence and operations, seamlessly integrates 
         into the modern IT environment." />
        <meta name="keywords" content="" />
      </Helmet>
        <div className="scroll-class">
            <div class="background-container bg-container-AIOP">
                <div class="centered-text">
                    <h1>May 5, 2023</h1>
                    <p>Why do we need AIOps and where does it fit with the modern IT environment?</p>
                </div>
            </div>

            <div className='scrolling-bar'>
                <div className='row space-above w-93 mx-auto'>
                    <div className="col-lg-3 col-md-12 col-sm-12 col-12 sticky-cls-first">
                        {headings
                            .filter((heading) => heading.title)
                            .map((heading) => (
                                <div
                                    key={heading.id}
                                    className={`heading ${activeHeading === heading.id ? "active" : ""}`}
                                    onClick={() => scrollToSection(heading.id)}
                                >
                                    {heading.title}
                                </div>
                            ))}
                        <button className='schedule-button'>Schedule a call</button>
                    </div>

                    <div className="col-lg-6 col-sm-12 col-md-12 col-12 descriptions-column">
                        <h1 className="reading-text">Reading time:</h1>
                        <div className="spacing-divs-xss"></div>
                        <div className="fs-12 fw-bold minute-text">
                            <img src={clock} className="me-2" />
                            4 minutes
                        </div>
                        {headings.map((heading, index) => (
                            <div
                                key={heading.id}
                                id={heading.id}
                                ref={(el) => (descriptionsRef.current[index] = el)}
                                className="description-section"
                            >
                                <div className="spacing-divs-l"></div>
                                {heading.title && <h1>{heading.title}</h1>}
                                {heading.description && <p>{heading.description}</p>}
                                {heading.description1 && <p>{heading.description1}</p>}
                                {heading.description2 && <p>{heading.description2}</p>}
                                {heading.description3 && <p>{heading.description3}</p>}
                                {heading.heading1 && <h2>{heading.heading1}</h2>}
                                {heading.description4 && <p>{heading.description4}</p>}
                                {heading.heading2 && <h3>{heading.heading2}</h3>}
                                {heading.heading3 && <h2>{heading.heading3}</h2>}
                                {heading.question && (
                                    <h5>
                                        <span className="question-style">{heading.question}</span>
                                        {heading.details && <span className="fs-14 fw-300">{heading.details}</span>}
                                    </h5>
                                )}
                                {heading.image && (
                                                    <img src={heading.image}  className='w-100'/>
                                                    
                                                )}
                                {heading.orderedlist && heading.orderedlist.length > 0 && (
                                    <ol className="pl-4">
                                        {heading.orderedlist.map((response, responseIndex) => (
                                            <li key={response.sidehead} className="fs-14 pb-3 ">
                                                <span className="fs-14 fw-bold" >{response.sidehead}</span>
                                                {response.content && <p className="mt-3">{response.content}</p>}
                                               
                                                {response.content1 && <p className="mt-3">{response.content1}</p>}
                                                {response.content2 && <p className="mt-3">{response.content2}</p>}
                                                {response.content3 && <p className="mt-3">{response.content3}</p>}
                                                {response.content4 && <p className="mt-3">{response.content4}</p>}
                                                {response.image && (
                                                    <img src={response.image}  className='w-100'/>
                                                    
                                                )}
                                            </li>
                                        ))}
                                    </ol>
                                )}
                                 {heading.description5 && <p className='fw-bold'>{heading.description5}</p>}
                                 {heading.description6 && <p className='fw-bold'>{heading.description6}</p>}
                                 {heading.description7 && <p className='fw-bold'>{heading.description7}</p>}
                            </div>
                        ))}


                        <div className="spacing-divs-ss"></div>
                        <div className='hr-line-category'></div>
                        <p className='category-font'>Category: <span className='ai-styles'>AI Industry News</span></p>
                        <div className='hr-line-category'></div>
                        <div className="share-container d-flex flex-row justify-content-between">
                            <div className='share-text'>Share this article:</div>
                            <div className="social-icons">
                                <span className="icon linkedin">
                                    <a href="https://www.linkedin.com" target='_blank'> <img src={linkedin} /></a>
                                </span>
                                <span className="icon linkedin">
                                    <a href="https://www.twitter.com" target='_blank'>  <img src={twitter} /></a>
                                </span>
                                <span className="icon linkedin">
                                    <a href="https://www.facebook.com" target='_blank'>  <img src={facebook} /></a>
                                </span>
                                <span className="linkedin">
                                    <a href="https://www.instagram.com" target='_blank'>  <img src={instagram} /></a>
                                </span>
                            </div>
                        </div>
                    </div>

                    <div className="col-lg-3 col-md-12 col-sm-12 col-12 sticky-cls mt-4">
                        <div className="card servicess">
                            <a href="https://addepto.com/contextclue-ai-text-analysis-tool-by-addepto/">
                                <div className="animation__img">
                                    <img src="https://addepto.com/wp-content/uploads/2023/06/contextclue-featured-image.webp" alt="" data-scroll-zoom />
                                </div>
                                <div className="card__content">
                                    <h5 className="h5">ContextClue: AI Text Analysis Tool by Addepto</h5>

                                    <div className="flex justify-content-end">
                                        <span className="h6 primary pt-3">check this service</span>
                                    </div>
                                </div>
                            </a>
                        </div>
                        <div class="flex fd-c ai-fs card newsletter">
                            <h2 class="h5">Sign Up for Newsletter</h2>
                            <form
                                ref={formDetails}
                                onSubmit={handleSubmit(submit)}
                                className="pt-4"
                            >
                                <div className='row'>
                                    <div className="col-xs-12 col-sm-12 col-12">
                                        <label className="font-form text-start ">
                                            Name<span className="green-star">*</span>
                                            <input
                                                className="w-100 spacing-input-l border-rounded"
                                                type="text"
                                                placeholder=' Name'
                                                name="fName"
                                                {...register("fName")}
                                            />
                                        </label>
                                        <p className="fs-13 text-danger fw-bold-400 text-start">
                                            {errors.fName?.message}
                                        </p>
                                    </div>
                                    <div className="col-xs-12 col-sm-12 col-12">
                                        <label className="font-form text-start">
                                            E-mail<span className="green-star">*</span>
                                        </label>
                                        <p className="email_field">
                                            <input
                                                className="w-100 spacing-input-l  border-rounded"
                                                placeholder='Email address'
                                                type="email"
                                                name="email"
                                                {...register("email")}
                                            />
                                        </p>
                                        <p className="fs-13 text-danger fw-bold-400 text-start">
                                            {errors.email?.message}
                                        </p>
                                    </div>
                                </div>
                                <div className="w-100 d-flex checkbox-space">
                                    <div className="w-6">
                                        <input
                                            className='checkmarks-in'
                                            type="checkbox"
                                            name="acceptCheckbox"
                                            {...register("acceptCheckbox")}
                                        ></input>
                                    </div>
                                    <div className="w-94 pl-2">
                                        <div className="pt-1 fs-12 ps-2">
                                            * I want to receive newsletter
                                        </div>
                                        <br />
                                    </div>
                                </div>
                                <p className="fs-13 text-danger fw-bold-400">
                                    {errors.acceptCheckbox?.message}
                                </p>
                                <p className='fs-12'>See our <span className='fw-bold fs-12'>Pivacy Policy</span></p>
                                <p className='signup-button'>Sign up</p>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
            <div className='grey-background'>
                <div className="row w-93 mx-auto new-case-studies-row ">
                    <div className="d-flex justify-content-between align-items-center  mb-3">
                        <div className="text-start related-articles">Related Articles</div>
                        <div className="text-end check-articles">check all Articles</div>
                    </div>
                    {Insightdata.AIOPenvironmentdetails.map((list) => {
                        return (
                            <div
                                className="col-lg-3 col-md-6 col-sm-12 col-xs-12 cr-pointer highlight-text d-flex align-items-strech mb-4"
                                key={list.id}
                                onClick={() => {
                                    navigateTowebinarspage(list.id);
                                }}
                                onMouseEnter={() => {
                                    hightlightText(list.id);
                                }}
                                onMouseLeave={() => {
                                    UnhightlightText();
                                }}
                            >
                                <div className="border-0  card animation-card-image mr-4 ">
                                    <a
                                        className="anchor_styles achorhover"
                                        href={`/blogs/${list.id}`}
                                    >
                                        <div className="card-header border-0 bgc-white p-0 position-relative">
                                            <img
                                                src={list.image}
                                                alt={list.title}
                                                className="blog_images"
                                            />
                                            <button className="btn btn-white position-absolute bottom-right-button">
                                                AI Industry News
                                            </button>
                                        </div>
                                        <div className="card-body propensity-modelling height-auto margin pb-2">
                                            <h1 className="color-date">{list.date}</h1>
                                            <h1 className="card-body-heading pb-0 pt-0">
                                                {list.title}
                                            </h1>
                                            <p className="card_body_description">
                                                {list.description}
                                            </p>
                                        </div>
                                    
                                    </a>
                                </div>
                            </div>
                        );
                    })}
                </div>
            </div>
        </div>
        </>

    );
};

export default AIOPenvironment;