import messageimg from "../../images/About-Us-Page/about-us-excellence.png";
const WhitepapersData = [
    {
        id: '1',
        image: messageimg,
        text: 'The problems with using spreadsheets and email for project management'

    },
    {
        id: '1',
        image: messageimg,
        text: 'Status meetings could be costing your organization valuable time and resources'

    },
    {
        id: '1',
        image: messageimg,
        text: 'Modern platforms offer real-time collaboration and advanced capabilities for project management'

    }
]

export default WhitepapersData;